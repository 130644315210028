import store from "@/store";
import { listPaginatedContacts } from "@/common/services/graphql.service";

const CONTACTS_LIMIT = 1000;

export const fetchContactsAsync = async (InstanceId, UserId) => {
  let allContacts = [];

  try {
    store.dispatch("contacts/markAsFetching");

    let { listPaginatedContacts: contacts } = await listPaginatedContacts({
      InstanceId,
      UserId,
      QueryType: "all",
      Limit: CONTACTS_LIMIT,
    });

    allContacts = [...contacts.items];
    let nextToken = contacts.exclusiveStartKey;

    while (nextToken) {
      ({ listPaginatedContacts: contacts } = await listPaginatedContacts({
        InstanceId,
        UserId,
        QueryType: "all",
        ExclusiveStartKey: nextToken,
        Limit: CONTACTS_LIMIT,
      }));

      allContacts.push(...contacts.items);
      nextToken = contacts.ExclusiveStartKey;
    }

    store.dispatch("contacts/updateContactList", allContacts);
    store.dispatch("contacts/markAsFetched");
  } catch (error) {
    console.error(error);
  }
};

// function createCustomEndpoints(contacts) {
//   let _contacts = [];
//   contacts.forEach((contact) => {
//     if (contact.WorkPhone) {
//       let endpoint = window.connect.Endpoint.byPhoneNumber(contact.WorkPhone);
//       endpoint.name = `${contact.Name} (Work: ${contact.WorkPhone})`;
//       _contacts.push(endpoint);
//     }
//     if (contact.HomePhone) {
//       let endpoint = window.connect.Endpoint.byPhoneNumber(contact.HomePhone);
//       endpoint.name = `${contact.Name} (Home: ${contact.HomePhone})`;
//       _contacts.push(endpoint);
//     }
//     if (contact.CellPhone) {
//       let endpoint = window.connect.Endpoint.byPhoneNumber(contact.CellPhone);
//       endpoint.name = `${contact.Name} (Cell: ${contact.CellPhone})`;
//       _contacts.push(endpoint);
//     }
//   });
//   return _contacts;
// }
