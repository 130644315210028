import { fetcherAmplify } from "@/libs/fetcher";
import {
  updateSettings as updateSettingsMutation,
  transferCustomTask as transferCustomTaskMutation,
  updatePhoneNumber as updatePhoneNumberMutation,
  deleteTrafficGroup as deleteTrafficGroupMutation,
  createTrafficGroup as createTrafficGroupMutation,
  removeUserFromGroup as removeUserFromGroupMutation,
  addUser2TrafficGroup as addUser2TrafficGroupMutation,
} from "@/graphql/mutations";

import {
  describePhoneNumber as describePhoneNumberQuery,
  describeTrafficDistributionGroup as describeTrafficDistributionGroupQuery,
  replicateInstance as replicateInstanceQuery,
  replicateCrossRegionResources as replicateCrossRegionResourcesQuery,
  getTrafficDistribution as getTrafficDistributionQuery,
  listTrafficDistributionGroups as listTrafficDistributionGroupsQuery,
  listTrafficDistributionGroupUsers as listTrafficDistributionGroupUsersQuery,
  listPhoneNumbers as listPhoneNumbersQuery,
  listPaginatedContacts as listPaginatedContactsQuery,
} from "@/graphql/queries";

const graphql = fetcherAmplify().graphql;

export const updateSettings = async (payload) => {
  const result = await graphql({
    query: updateSettingsMutation,
    variables: {
      input: payload,
    },
  });

  return result;
};

export const transferCustomTask = async (payload) => {
  const result = await graphql({
    query: transferCustomTaskMutation,
    variables: payload,
  });

  return result;
};

export const listPaginatedContacts = async ({
  InstanceId,
  UserId,
  QueryType,
  QueryString,
  TargetPage,
  ExclusiveStartKey,
  Limit,
}) => {
  const result = await graphql({
    query: listPaginatedContactsQuery,
    variables: {
      InstanceId,
      UserId,
      QueryType,
      QueryString,
      TargetPage,
      ExclusiveStartKey,
      Limit,
    },
  });

  return result;
};

export const updatePhoneNumber = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: updatePhoneNumberMutation,
    variables: payload,
  });

  return result;
};

export const describePhoneNumber = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: describePhoneNumberQuery,
    variables: payload,
  });

  return result;
};

export const describeTrafficDistributionGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: describeTrafficDistributionGroupQuery,
    variables: payload,
  });

  return result;
};

export const replicateInstance = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: replicateInstanceQuery,
    variables: payload,
  });

  return result;
};

export const replicateCrossRegionResources = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: replicateCrossRegionResourcesQuery,
    variables: payload,
  });

  return result;
};

export const deleteTrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: deleteTrafficGroupMutation,
    variables: payload,
  });

  return result;
};

export const getTrafficDistribution = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: getTrafficDistributionQuery,
    variables: payload,
  });

  return result;
};

export const listTrafficDistributionGroups = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listTrafficDistributionGroupsQuery,
    variables: payload,
  });

  return result;
};

export const listTrafficDistributionGroupUsers = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listTrafficDistributionGroupUsersQuery,
    variables: payload,
  });

  return result;
};

export const listPhoneNumbers = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: listPhoneNumbersQuery,
    variables: payload,
  });

  return result;
};

export const createTrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: createTrafficGroupMutation,
    variables: payload,
  });

  return result;
};

export const removeUserFromGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: removeUserFromGroupMutation,
    variables: payload,
  });

  return result;
};

export const addUser2TrafficGroup = async (payload) => {
  const result = await fetcherAmplify().graphql({
    query: addUser2TrafficGroupMutation,
    variables: payload,
  });

  return result;
};
